/**
 * @flow
 */

import { graphql } from 'gatsby';
import ButtonLink from 'components/ButtonLink';
import Container from 'components/Container';
import Flex from 'components/Flex';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Layout from 'components/layout';
import TitleAndMetaTags from 'components/TitleAndMetaTags';
import { colors, media, sharedStyles } from 'theme';
import createOgUrl from 'utils/createOgUrl';
import logoWhiteSvg from 'icons/logo-white.svg';

import TenantSvg from 'components/svg/Tenant';
import DwellingSvg from 'components/svg/Dwelling';

import type { Node } from 'react';

type Props = {
  location: Location,
  data: mixed
};

type State = {
  marketing: mixed,
  partners: mixed
};

class Home extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { data } = props;

    const marketing = data.marketing.edges.map(({ node }) => ({
      title: node.frontmatter.title,
      content: node.html
    }));

    const partners = data.partners.edges.map(({ node }) => ({
      title: node.frontmatter.title,
      permalink: node.frontmatter.permalink,
      image: node.frontmatter.image,
      content: node.html
    }));

    this.state = {
      marketing,
      partners
    };
  }

  render() {
    const { marketing, partners } = this.state;
    const { location } = this.props;

    return (
      <Layout location={location}>
        <TitleAndMetaTags
          title="Lejeloven - Dansk lov om leje" // så den er til at forstå
          ogUrl={createOgUrl('/')}
        />
        <div css={{ width: '100%' }}>
          <header
            css={{
              backgroundColor: colors.dark,
              color: colors.white
            }}>
            <div
              css={{
                paddingTop: 45,
                paddingBottom: 20,

                position: 'relative',
                '::before': {
                  content: ' ',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  backgroundImage: `url(${logoWhiteSvg})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: '250% 50px',
                  backgroundSize: '80% auto',
                  opacity: 0.05
                },

                [media.greaterThan('small')]: {
                  paddingTop: 60,
                  paddingBottom: 70,

                  '::before': {
                    content: ' ',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    backgroundImage: `url(${logoWhiteSvg})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '125% 75px',
                    backgroundSize: '50% auto',
                    opacity: 0.1
                  }
                },

                [media.greaterThan('xlarge')]: {
                  paddingTop: 80,
                  paddingBottom: 80,
                  maxWidth: 1440, // Positioning of background logo
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  // position: 'relative',
                  '::before': {
                    content: ' ',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    backgroundImage: `url(${logoWhiteSvg})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '95% 50px',
                    backgroundSize: '50% auto',
                    opacity: 0.1
                  }
                }
              }}>
              <div
                css={{
                  // Content should be above absolutely-positioned hero image
                  position: 'relative'
                }}>
                <Container>
                  <h1
                    css={{
                      color: colors.brand,
                      textAlign: 'center',
                      margin: 0,
                      fontSize: 45,
                      letterSpacing: '0.01em',
                      [media.size('xsmall')]: {
                        fontSize: 30
                      },
                      [media.greaterThan('xlarge')]: {
                        fontSize: 60
                      }
                    }}>
                    Lejeloven
                  </h1>
                  <p
                    css={{
                      paddingTop: 15,
                      textAlign: 'center',
                      fontSize: 24,
                      letterSpacing: '0.01em',
                      fontWeight: 200,

                      [media.size('xsmall')]: {
                        fontSize: 16,
                        maxWidth: '12em',
                        marginLeft: 'auto',
                        marginRight: 'auto'
                      },

                      [media.greaterThan('xlarge')]: {
                        paddingTop: 20,
                        fontSize: 30
                      }
                    }}>
                    så den er til at forstå
                  </p>

                  <CtaItems
                    css={{
                      marginLeft: -10,
                      marginRight: -10,

                      paddingTop: 40,

                      [media.greaterThan('xlarge')]: {
                        paddingTop: 65
                      }
                    }}
                  />
                </Container>
              </div>
            </div>
          </header>

          <Container>
            <div css={sharedStyles.markdown}>
              {marketing && marketing.length ? (
                <section
                  css={[
                    sectionStyles
                    // {
                    //   [media.lessThan('medium')]: {
                    //     marginTop: 0,
                    //     marginBottom: 0,
                    //     overflowX: 'auto',
                    //     paddingTop: 30,
                    //     WebkitOverflowScrolling: 'touch',
                    //     position: 'relative',
                    //     maskImage:
                    //       'linear-gradient(to right, transparent, white 10px, white 90%, transparent)'
                    //   }
                    // }
                  ]}>
                  <div
                    css={{
                      display: 'flex',
                      flexDirection: 'row',

                      [media.lessThan('medium')]: {
                        display: 'block'
                        // whiteSpace: 'nowrap'
                      }
                    }}>
                    {marketing.map((column, index) => (
                      <div
                        key={index}
                        css={{
                          display: 'flex',
                          flexDirection: 'column',
                          flex: '0 1 33%',
                          marginLeft: 40,

                          '&:first-of-type': {
                            marginLeft: 0

                            // [media.lessThan('medium')]: {
                            //   marginLeft: 10
                            // }
                          },

                          [media.lessThan('medium')]: {
                            verticalAlign: 'top',
                            paddingBottom: 40,
                            marginLeft: 0,
                            flex: 1,
                            width: '100%'
                            //   display: 'inline-block',
                            //   verticalAlign: 'top',
                            //   marginLeft: 0,
                            //   whiteSpace: 'normal',
                            //   width: '75%',
                            //   marginRight: 20,
                            //   paddingBottom: 40,
                            //
                            //   '&:first-of-type': {
                            //     marginTop: 0
                            //   }
                          }

                          // ' h3': [
                          //   headingStyles,
                          //   {
                          //     '&&': {
                          //       // Make specificity higher than the site-wide h3 styles.
                          //       color: colors.subtle,
                          //       paddingTop: 0,
                          //       fontWeight: 300,
                          //       fontSize: 20,
                          //
                          //       [media.greaterThan('xlarge')]: {
                          //         fontSize: 24,
                          //         fontWeight: 200
                          //       }
                          //     }
                          //   }
                          // ]
                        }}>
                        {/* <h3
                        css={[
                          headingStyles,
                          {
                            '&&': {
                              // Make specificity higher than the site-wide h3 styles.
                              color: colors.subtle,
                              paddingTop: 0,
                              fontWeight: 300,
                              fontSize: 20,

                              [media.greaterThan('xlarge')]: {
                                fontSize: 24,
                                fontWeight: 200
                              }
                            }
                          }
                        ]}>
                        {column.title}
                      </h3> */}
                        <div
                          css={{
                            '& hr': { marginTop: '25px !important' },
                            '& > h3': [
                              headingStyles,
                              {
                                // '&&': {
                                // Make specificity higher than the site-wide h3 styles.
                                color: colors.subtle,
                                paddingTop: 30,
                                fontWeight: 300,
                                fontSize: 20,

                                [media.greaterThan('xlarge')]: {
                                  fontSize: 24,
                                  fontWeight: 200
                                },

                                '&:first-of-type': {
                                  paddingTop: 0
                                }
                                // }
                              }
                            ]
                          }}
                          dangerouslySetInnerHTML={{ __html: column.content }}
                        />
                      </div>
                    ))}
                  </div>
                </section>
              ) : null}

              {partners && partners.length ? (
                <section css={[sectionStyles]}>
                  <div
                    css={{
                      display: 'flex',
                      flexDirection: 'row',

                      [media.lessThan('medium')]: {
                        display: 'block'
                      }
                    }}>
                    {partners.map((column, index) => (
                      <div
                        key={index}
                        css={{
                          display: 'flex',
                          flexDirection: 'column',
                          flex: '0 1 33%',
                          marginLeft: 40,

                          '&:first-of-type': {
                            marginLeft: 0
                          },

                          [media.lessThan('medium')]: {
                            verticalAlign: 'top',
                            paddingBottom: 40,
                            marginLeft: 0,
                            flex: 1,
                            width: '100%'
                          }
                        }}>
                        <a
                          href={column.permalink}
                          target="_blank"
                          css={{
                            display: 'inline-block',
                            marginTop: '1em',
                            marginBottom: '0.25em',
                            lineHeight: 1,
                            textAlign: 'center',
                            background: '#fff !important',
                            ...(column.image === '/logo-digura.png'
                              ? { padding: '5%' }
                              : column.image === '/logo-akutbolig.png'
                              ? { padding: '2% 0' }
                              : {})
                          }}>
                          <img
                            alt={column.title}
                            src={column.image}
                            css={
                              column.image === '/logo-digura.png'
                                ? { width: '65%' }
                                : { width: '100%' }
                            }
                          />
                        </a>

                        <div
                          css={{
                            '& hr': { marginTop: '25px !important' },
                            '& > h3': [
                              headingStyles,
                              {
                                color: colors.subtle,
                                paddingTop: 30,
                                fontWeight: 300,
                                fontSize: 20,

                                [media.greaterThan('xlarge')]: {
                                  fontSize: 24,
                                  fontWeight: 200
                                },

                                '&:first-of-type': {
                                  paddingTop: 0
                                }
                              }
                            ]
                          }}
                          dangerouslySetInnerHTML={{ __html: column.content }}
                        />
                      </div>
                    ))}
                  </div>
                </section>
              ) : null}
            </div>
          </Container>

          <section
            css={{
              background: colors.dark,
              color: colors.white,
              paddingTop: 45,
              paddingBottom: 45
            }}>
            <Container>
              <CtaItems />
            </Container>
          </section>
        </div>
      </Layout>
    );
  }
}

Home.propTypes = {
  data: PropTypes.shape({
    // code: PropTypes.object.isRequired,
    marketing: PropTypes.object.isRequired,
    partners: PropTypes.object.isRequired
  }).isRequired,
  location: PropTypes.object.isRequired
};

const CtaItem = ({
  children,
  primary = false
}: {
  children: Node,
  primary?: boolean
}) => (
  <div
    css={{
      // width: '50%',

      padding: '0 10px',

      flexGrow: 1,
      textAlign: 'center',
      width: '50%',
      maxWidth: '250px',

      [media.between('small', 'large')]: {
        // maxWidth: '250px',
        padding: '0 20px'
      },

      [media.greaterThan('xlarge')]: {
        maxWidth: '350px',
        padding: '0 40px'
      },

      '& > :first-child': {
        boxShadow: '0 3px 6px rgba(0,0,0,0.3)',
        width: '100%'
      }

      // '&:first-child': {
      //   textAlign: 'right',
      //   paddingRight: 15,
      // },
      //
      // '&:nth-child(2)': {
      //   [media.greaterThan('small')]: {
      //     paddingLeft: 15,
      //   },
      // },
    }}>
    {children}
  </div>
);

const CtaItems = props => {
  return (
    <Flex valign="center" halign="center" {...props}>
      <CtaItem>
        <ButtonLink to="/lejer/" type="primary">
          <TenantSvg
            size={48}
            cssProps={{ display: 'block', margin: '0 auto 10px' }}
          />
          for <strong>lejere</strong>
        </ButtonLink>
      </CtaItem>
      <CtaItem>
        <ButtonLink to="/udlejer/" type="primary">
          <DwellingSvg
            size={48}
            cssProps={{ display: 'block', margin: '0 auto 10px' }}
          />
          for <strong>udlejere</strong>
        </ButtonLink>
      </CtaItem>
    </Flex>
  );
};

// eslint-disable-next-line no-undef
export const pageQuery = graphql`
  {
    # code: allExampleCode {
    #   edges {
    #     node {
    #       id
    #       internal {
    #         contentDigest
    #       }
    #     }
    #   }
    # }
    # examples: allMarkdownRemark(
    #   filter: { fileAbsolutePath: { regex: "//home/examples//" } }
    #   sort: { fields: [frontmatter___order], order: ASC }
    # ) {
    #   edges {
    #     node {
    #       fields {
    #         slug
    #       }
    #       frontmatter {
    #         title
    #       }
    #       html
    #     }
    #   }
    # }
    marketing: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//home/marketing//" } }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          html
        }
      }
    }
    partners: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//home/partners//" } }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
            permalink
            image
          }
          html
        }
      }
    }
  }
`;

export default Home;

const sectionStyles = {
  marginTop: 20,
  marginBottom: 15,

  [media.greaterThan('medium')]: {
    marginTop: 60,
    marginBottom: 65
  },

  border: `0 solid ${colors.divider}`,
  borderWidth: '0 0 1px',
  paddingBottom: 60,

  '&:first-of-type': {
    border: 0,
    paddingBottom: 0
  }
};

const headingStyles = {
  '&&': {
    marginBottom: 20
  }
};
